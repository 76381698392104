import { queryStrAtr, hashtags } from "@/plugins/utils.js";
import Vue from "vue";

const calcPerc = (total, amount, precision) => {
  return total > 0 ? Math.round((amount / total) * 100, precision || 0) : 0;
};

const initQuery = () => ({
  query_string: "",
  query_connection: {
    checked: "all",
    disabled: false
  },
  query_alarm: false,
  query_ids: null,
  query_process_area: {
    id: ""
  },
  query_show_stats: true,
  query_filtered_nodes: {
    connector: {},
    device: {},
    show: false,
    sid: 0
  }
});

/*
  Simple crud for local storage
  get    : _db = (id)
  save   : _db = (id, payload)
  remove : _db = (id, null)
*/
const _db = (key, payload) => {
  if (payload && typeof payload == "object") {
    localStorage.setItem(key, JSON.stringify(payload));
    return payload;
  } else if (typeof payload == "undefined") {
    let item = localStorage.getItem(key);
    if (item || typeof item == "string") {
      return { ...initQuery(), ...JSON.parse(item) };
    }
    return null;
  } else if (payload === null) {
    localStorage.removeItem(key);
    return null;
  }
};

const initialState = () => {
  return {
    source: "device",
    scope: "all",
    query: _db("query") || initQuery()
  };
};

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    RESET(state) {
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    SET_SOURCE(state, value) {
      state.source = value;
    },
    SET_QUERY(state, value) {
      Vue.set(state, "query", value);
    }
  },
  actions: {
    reset: (context) => {
      _db("query", null);
      context.commit("RESET");
    },
    setSource: (context, value) => {
      context.commit("SET_SOURCE", value);
    },
    setQuery: (context, value) => {
      context.commit("SET_QUERY", value);
      _db("query", value);
    }
  },
  getters: {
    query: (state) => {
      return state.query;
    },
    equipmentStoreList: (state, getters, rootState, rootGetters) => {
      return (rootGetters["dashboard/connectorList"] || [])
        .map((connector) => {
          return {
            ...connector,
            ...{
              devices: (rootGetters["dashboard/deviceList"] || []).filter(
                ({ connector_id }) => connector_id == connector.id
              )
            }
          };
        })
        .filter((i) => !i.base_model);
    },
    deviceList: (state, getters) => {
      let lst = [];
      let all = getters.equipmentStoreList;
      for (var i in all) {
        let equipment = all[i];
        for (var j in equipment.devices) {
          let newEquipment = JSON.parse(JSON.stringify(equipment));
          newEquipment.device = equipment.devices[j];
          lst.push(newEquipment);
        }
      }
      return lst;
    },
    filteredList: (state, getters) => {
      const processArea = getters.processArea;
      // It is previous equipmentList function implementation from equipmentSearchBase component
      const qtags = hashtags(state.query.query_string, true);

      const validateConnector = (item) => {
        var search = false;
        if (qtags.tags.length && (item?.portal_data?.tags || []).length) {
          for (var i in item.portal_data.tags) {
            if (
              qtags.tags.indexOf(
                (item.portal_data.tags[i]?.text || "").toLowerCase()
              ) >= 0
            ) {
              if (qtags.text) {
                search = queryStrAtr(qtags.text, item); // not deep
              } else {
                search = true;
              }
              break;
            }
          }
        } else {
          search = queryStrAtr(state.query.query_string, item, "any"); // deep search
        }
        return (
          // validate query string
          search &&
          // validate connection state
          (state.query.query_connection.checked == "all" ||
            (state.query.query_connection.checked == "connected" &&
              item.is_connected) ||
            (state.query.query_connection.checked == "disconnected" &&
              !item.is_connected)) &&
          // validate enabled state
          (state.query.query_connection.disabled ? !item.enabled : true) &&
          // validate alarm state
          (!state.query.query_alarm ||
            (state.query.query_alarm && item.has_active_alarms)) &&
          // validate filter object
          (!processArea || !processArea?.ids?.length
            ? true
            : processArea.ids.indexOf(item.id) >= 0)
        );
      };

      const validateDevice = (item) => {
        var search = false;
        if (qtags.tags.length && (item?.portal_data?.tags || []).length) {
          for (var i in item.portal_data.tags) {
            if (
              qtags.tags.indexOf(
                (item.portal_data.tags[i]?.text || "").toLowerCase()
              ) >= 0
            ) {
              if (qtags.text) {
                search = queryStrAtr(qtags.text, item); // not deep
              } else {
                search = true;
              }
              break;
            }
          }
        } else {
          search = queryStrAtr(state.query.query_string, item, "any"); // deep search
        }
        var connection =
          state.query.query_connection.checked == "all" ||
          (state.query.query_connection.checked == "connected" &&
            (item.is_connected || item.device.is_connected)) ||
          (state.query.query_connection.checked == "disconnected" &&
            !state.query.query_connection.disabled &&
            (!item.is_connected || !item.device.is_connected) &&
            (item.enabled || item.device.enabled));

        var disabled =
          state.query.query_connection.disabled &&
          (!item.enabled || !item.device.enabled);

        return (
          // validate query string
          search &&
          // validate connection state
          // https://hitecnologia.bitrix24.com.br/extranet/workgroups/group/47/tasks/task/view/17804/?IFRAME=Y&IFRAME_TYPE=SIDE_SLIDER&MID=20054#com20054
          (connection ||
            // validate enabled state
            disabled) &&
          // validate alarm state
          (!state.query.query_alarm ||
            (state.query.query_alarm && item.device.has_active_alarms)) &&
          // validate filter object

          (!processArea || !processArea?.ids?.length
            ? true
            : processArea.ids.indexOf(item.device.id) >= 0)
        );
      };

      if (getters.entryList.length) {
        return getters.source == "device"
          ? getters.selectedDeviceList.filter(validateDevice)
          : getters.source == "connector"
            ? getters.enabledItems.filter(validateConnector)
            : getters.source == "all"
              ? getters.entryList
              : getters.enabledItems;
      }
      return getters.entryList;
    },
    entryList: (state, getters) => {
      return state.source == "connector"
        ? getters.equipmentStoreList
        : getters.deviceList;
    },
    enabledItems: (state, getters) => {
      return getters.entryList.filter((item) =>
        state.source == "device" ? item.device.enabled : item.enabled
      );
    },
    disabledItems: (state, getters) => {
      return getters.entryList.filter((item) =>
        state.source == "device" ? !item.device.enabled : !item.enabled
      );
    },
    connected: (state, getters) => {
      return getters.entryList.filter((item) =>
        state.source == "device"
          ? item.device.enabled && item.device.is_connected
          : item.enabled && item.is_connected
      );
    },
    disconnected: (state, getters) => {
      return getters.entryList.filter((item) =>
        state.source == "device"
          ? item.device.enabled && !item.device.is_connected
          : item.enabled && !item.is_connected
      );
    },
    alarmed: (state, getters) => {
      return getters.entryList.filter((item) =>
        state.source == "device"
          ? item.device.has_active_alarms
          : item.has_active_alarms
      );
    },
    scope(state) {
      return state?.query?.query_connection?.checked == "connected" ||
        (state?.query?.query_connection?.checked == "disconnected" &&
          !state?.query?.query_connection?.disabled)
        ? "enabled"
        : "all";
    },
    source(state) {
      return state.source;
    },
    stats: (state, getters) => {
      // filtered: (this.equipmentList || []).length
      return {
        available: getters.entryList.length,
        enabled: getters.enabledItems.length,
        disabled: getters.disabledItems.length,
        connected: getters.connected.length,
        disconnected: getters.disconnected.length,
        alarmed: getters.alarmed.length,
        source: {
          from: getters.source,
          scope: getters.scope,
          available:
            (getters.scope == "all" ? getters.entryList : getters.enabledItems)
              ?.length || 0,
          filtered: (getters.filteredList || []).length
        },
        query: state.query
      };
    },
    connectorProcessAreaList(state, getters) {
      let lst = getters.equipmentStoreList || [];
      let processAreas = {};
      for (var i in lst) {
        var item = lst[i];
        var cpa = item.process_area;
        if (!(cpa.id in processAreas)) {
          processAreas[cpa.id] = {
            id: cpa.id,
            name: cpa.name,
            title: cpa.description || cpa.name,
            nStations: 0,
            nConnectedStations: 0,
            nAlarmedStations: 0,
            ids: []
          };
        }
        processAreas[cpa.id].nStations += 1;
        processAreas[cpa.id].nConnectedStations += item.is_connected ? 1 : 0;
        processAreas[cpa.id].nAlarmedStations += item.has_active_alarms ? 1 : 0;
        processAreas[cpa.id].ids.push(item.id);
      }
      let items = null;
      if ("values" in Object) {
        items = Object.values(processAreas);
      } else {
        items = []; // safari/edge not compatible
        for (var j in processAreas) {
          items.push(processAreas[j]);
        }
      }
      return items;
    },
    deviceProcessAreaList(state, getters) {
      let lst = getters.deviceList || [];
      let processAreas = {};
      for (var i in lst) {
        var item = lst[i].device;
        var cpa = item.process_area;
        if (!(cpa.id in processAreas)) {
          processAreas[cpa.id] = {
            id: cpa.id,
            name: cpa.name,
            title: cpa.description || cpa.name,
            nStations: 0,
            nConnectedStations: 0,
            nAlarmedStations: 0,
            ids: []
          };
        }
        processAreas[cpa.id].nStations += 1;
        processAreas[cpa.id].nConnectedStations += item.is_connected ? 1 : 0;
        processAreas[cpa.id].nAlarmedStations += item.has_active_alarms ? 1 : 0;
        processAreas[cpa.id].ids.push(item.id);
      }
      let items = null;
      if ("values" in Object) {
        items = Object.values(processAreas);
      } else {
        items = []; // safari/edge not compatible
        for (var j in processAreas) {
          items.push(processAreas[j]);
        }
      }
      return items;
    },
    connectionStats: (state, getters) => {
      let stats = getters.stats;
      // connection card extension
      let entry = {
        available: stats.source.available,
        amount: 0,
        perc: 100
      };
      if (stats.query.query_connection.disabled) {
        entry.amount = stats.disabled;
      } else if (stats.query.query_connection.checked == "all") {
        entry.amount = stats.source.available;
      } else if (stats.query.query_connection.checked == "connected") {
        entry.amount = stats.connected;
      } else if (stats.query.query_connection.checked == "disconnected") {
        entry.amount = stats.disconnected;
      }
      entry.perc = calcPerc(entry.available, entry.amount);
      return {
        ...stats,
        entry: entry
      };
    },
    alarmStats: (state, getters) => {
      let stats = getters.stats;
      let entry = {
        available: stats.available,
        amount: stats.alarmed,
        perc: calcPerc(stats.available, stats.alarmed)
      };
      return {
        ...stats,
        entry: entry
      };
    },
    equipmentProcessAreaList: (state, getters) => {
      return getters.source == "connector"
        ? getters.connectorProcessAreaList
        : getters.deviceProcessAreaList;
    },
    processArea: (state, getters) => {
      if (state?.query?.query_process_area?.id) {
        return (getters.equipmentProcessAreaList || []).find(
          ({ id }) => id == state.query.query_process_area.id
        );
      }
      return null;
    },
    processAreaStats: (state, getters) => {
      let stats = getters.stats;
      let lst = getters.equipmentProcessAreaList;
      let processArea = getters.processArea;
      let entry = {
        available: stats.available,
        amount: processArea ? (processArea?.ids || []).length : stats.available,
        nProcessAreas: lst.length,
        processArea: processArea
      };
      entry.perc = calcPerc(entry.available, entry.amount);
      return {
        ...stats,
        entry: entry
      };
    },
    selectedDeviceList: (state, getters) => {
      var lst =
        getters.scope == "all" ? getters.entryList : getters.enabledItems;
      var cfg = state?.query?.query_filtered_nodes;
      if (
        cfg &&
        getters.source == "device" &&
        lst &&
        lst.length &&
        Object.values(cfg.connector || {}).filter((v) => v).length
      ) {
        let withFilter = {};
        lst = lst.filter(({ device, id }) => {
          var ret = (cfg.connector || {})[id];
          if (ret) {
            withFilter[id] = withFilter[id] ?? false;
            if (cfg.device[device.id]) {
              withFilter[id] = true;
            }
          }
          return ret;
        });
        if (Object.values(cfg.device || {}).filter((v) => v).length) {
          lst = lst.filter(({ device, id }) => {
            if (withFilter[id]) {
              return (cfg.device || {})[device.id] ? true : false;
            }
            return true;
          });
        }
      }
      return lst;
    },
    hasSelectedDevices: (state, getters) => {
      return getters.selectedDeviceList.length != getters.deviceList.length;
    }
  }
};
