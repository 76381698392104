import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import { parseMessage } from "@/utils";
import Utils from "@/plugins/utils.js";

Vue.use(VueResource);
Vue.use(Utils);
const _vue = new Vue();
/*
public methods:
  fetch - request a list of items
  get - request a single item
  save - request to save or update a single item
  remove - request to remove a single item
  duplicate - request an item copy
  */

const baseurl = ""; //v1  set to "" after conversion

// const simulation = function(lst) {
//   const _tests = {
//     "43904": {
//       value: `${Array(10)
//         .fill("HI Tecnologia")
//         .join(" ")}` // string
//     },
//     "43903": {
//       value: parseInt(Math.random() * 1000) // duration
//     },
//     "43912": {
//       value: 1 // boolean
//     },
//     "43911": {
//       value: parseFloat(Math.random() * 100) // float
//     },
//     "43910": {
//       value: parseInt(Math.random() * 1000) // integer
//     },
//     "43913": {
//       value: parseInt(Math.random() * 10) // text list (0~10)
//     }
//   };
//   (lst || [])
//     .filter(({ id }) => _tests[id])
//     .forEach((item) => {
//       item.current_value = {
//         ..._tests[item.id],
//         date_time: new Date().toISOString(),
//         id: item.id
//       };
//     });
// };

//
// const simulation = function(lst) {
//   (lst || []).forEach((item) => {
//     if (
//       // is it?
//       item?.device?.data_collector_device_id && // a virtual device?
//       item?.device?.reference_device_id // based on device model?
//     ) {
//       item.memory_address = "";
//       item.local_storage_identity_number = "";
//     }
//   });
// };

// begin test: assign id to current_value
// const simulation = function(lst) {
//   (lst || []).forEach((item) => {
//     item.current_value = {
//       value: item.id,
//       date_time: new Date().toISOString(),
//       id: item.id
//     };
//   });
// };4878

// begin test: data value as vector
// begin - array index simulation
// const simulation = (lst) => {
//   const memorySize = 20;
//   (lst || []).forEach((item) => {
//     // 44618 - mqtt connector - 
//     // 63594 - con vector     - 5048
//     if ((item.id == 44618 || item.id == 63594) && item.current_value) {
//       // fetchData (complete)
//       item.memory_size = memorySize;
//       item.current_value = {
//         value: `[${Array.from({ length: memorySize }).map((i, x) => `${x}.${x % 10}0${x % 10}`).join(',')}]`,
//         date_time: new Date().toISOString(),
//         id: item.id
//       };
//       // item.portal_data = item.portal_data || {};
//       // item.portal_data.data_value_index = {
//       //   type: "constant",
//       //   data_id: "",
//       //   value: 3,
//       //   offset: 1
//       // }
//     }
//     else if (item.data_id == 44618 || item.data_id == 63594) {
//       // fetchSamples (only values)
//       item.value = `[${Array.from({ length: memorySize }).map((i, x) => `${x}.${x % 10}0${x % 10}`).join(',')}]`;
//     }
//   });
// };

window.DataService = window.DataService || { cache: {} };

export default class DataService {
  async fetch(query, URL) {
    return new Promise((resolve) => {
      let url = URL || `${baseurl}data/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          url += `&${prop}=${query[prop]}`;
        }
      }
      let $ck = Object.keys(query)
        .sort()
        .map((k) => `${k}${query[k]}`)
        .join("");
      const now = new Date().getTime();
      // console.log(`${now} ${$ck}`);
      const _cache = window.DataService.cache;
      if (_cache[$ck]) {
        // already waiting a request or has already an answer
        const _inCache = ($ck, cb, retries) => {
          retries = retries === undefined ? 50 : retries;
          let elapsed = now - _cache[$ck]._;
          if (elapsed > _cache[$ck].$) {
            delete _cache[$ck];
          } else {
            if (_cache[$ck].content !== undefined) {
              // console.log(`cache used ${retries} ${elapsed}`);
              cb(_cache[$ck].content);
              return;
            }
            retries--;
            if (retries > 0) {
              // console.log(`attempt ${retries}`);
              setTimeout(
                () => _inCache($ck, cb, retries),
                _cache[$ck].$ / 50,
                this
              );
            } else {
              // console.log(`retries expired ${retries} ${elapsed}`);
              delete _cache[$ck];
              cb([]);
              return;
            }
          }
          return;
        };
        _inCache($ck, (result) => {
          resolve(result);
        });
        if (_cache[$ck]) return;
      }
      // console.log(`${now} ${$ck} requesting`);
      _cache[$ck] = { _: now, $: 5000 };
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          _cache[$ck].content = response.body;
          if (response && response.body) {
            // TODO: Remove after test it.
            // begin test
            // simulation(response.body);
            // end test
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          _cache[$ck].content = [];
          resolve(null);
        }
      );
    });
  }

  async save(payload) {
    return new Promise((resolve) => {
      let url = `${baseurl}data/`; //v1
      let request = null;
      let auth = new Auth();
      if (("id" in payload && payload.id) || payload.length) {
        let lst = (payload.id ? [payload] : payload).filter(
          ({ id, etag }) => (id && etag && true) || false
        );
        if (!lst.length) {
          resolve("invalid_data_set");
          return;
        }
        request = Vue.http.patch(url, { data: lst }, auth.requestOptions());
      } else {
        request = Vue.http.post(url, payload, auth.requestOptions());
      }
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = null,
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async export(params, fname) {
    fname = fname || "data.csv";
    return new Promise((resolve) => {
      let url = `${baseurl}data/export/?${params}`; //v1
      let auth = new Auth();
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            _vue.$utils.download(response.bodyText, "text/csv", fname);
            return;
          }
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async import(payload) {
    return new Promise((resolve) => {
      let url = `${baseurl}data/import/`; //v1
      let request = null;
      let auth = new Auth();
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async duplicate(id, payload) {

    return new Promise((resolve) => {
      let url = `${baseurl}data/${id}/duplicate/`; //v1
      let auth = new Auth();
      let request = Vue.http.post(
        url,
        payload,
        auth.requestOptions(payload.etag ? { "If-Match": payload.etag } : null)
      );
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          let msg = [],
            body = error.body || {};
          if (body.detail) msg = body.detail;
          else msg = Object.entries(body);
          resolve(msg);
        }
      );
    });
  }

  async get(id, contract_id) {
    return new Promise((resolve) => {
      let url = `${baseurl}data/${id}/?format=json`; //v1
      if (contract_id) {
        url += "&contract_id=" + contract_id;
      }
      let auth = new Auth();
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            //begin test
            // simulation([response.body]);
            //end test
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async remove(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}data/${payload.id}/?format=json`; //v1
      let auth = new Auth();
      Vue.http
        .delete(
          url,
          auth.requestOptions(
            payload.etag ? { "If-Match": payload.etag } : null
          )
        )
        .then(
          (response) => {
            resolve({});
          },
          (error) => {
            resolve(
              error.body?.detail ||
              parseMessage(error.body ?? {}) ||
              "Failed to delete"
            );
          }
        );
    });
  }

  async removeMultiple(payload) {
    let self = this;
    return new Promise((resolve) => {
      let url = `${baseurl}data/remove/`; //v1
      let auth = new Auth();
      Vue.http.post(url, payload, auth.requestOptions()).then(
        (response) => {
          resolve({});
        },
        (error) => {
          resolve(
            error.body?.detail ||
            parseMessage(error.body ?? {}) ||
            "Failed to delete"
          );
        }
      );
    });
  }
  /*
  operator:
    before: last values before date time (query.start => query.values_at) (before)
    between: samples within the start/end interval (between)
    after: next values after date time (query.end => query.values_at) (after)
    current: current data value
  */
  async fetchSamples(query, operator) {
    return new Promise((resolve) => {
      let opt = {
        before: "data_before_value_at",
        between: "data_history",
        after: "data_after_value_at",
        current: "data_current_value"
      }[operator || "between"];
      if (!opt) {
        resolve([]);
        return;
      }
      let url = `${baseurl}${opt}/?format=json`;
      let auth = new Auth();
      if (query) {
        let params = JSON.parse(JSON.stringify(query));
        if (opt != "data_history") {
          if (opt == "data_before_value_at") {
            params.value_at = params.start;
          } else if (opt == "data_after_value_at") {
            params.value_at = params.end;
          }
          delete params.start;
          delete params.end;
        }
        for (var prop in params) {
          let vlr = encodeURIComponent(params[prop]);
          // let vlr = params[prop];
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            // BEGIN TEST
            // if (simulation) {
            //   console.error("BE CAREFULl! since simulation is ON in data.fetchSamples (data service)")
            // simulation(response.body);
            // }
            // END TEST
            // resolve(response.body);
            let dt;
            const ret = (response.body || []).map((i) => {
              try {
                dt = new Date(i.date_time)
                i.restore_date_time = i.date_time;
                i.restore_value = i.value;
                i.date_time = new Date(dt.getTime() - dt.getMilliseconds()).toISOString();
                if (!i.identity_name && i.name) i.identity_name = i.name;
              } catch (error) {
                console.log(`invalid date_time property value ${i.id || i.data_id}`)
              }
              return i;
            });
            resolve(ret);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          let msg = [];
          for (var i in body) {
            msg.push(i + ": " + body[i]);
          }
          resolve(msg.join("\n"));
        }
      );
    });
  }

  async saveSamples(payload) {
    let auth = new Auth();
    return new Promise((resolve, reject) => {
      let url = `${baseurl}data_current_value/`; //v1
      let request = null;
      request = Vue.http.post(url, payload, auth.requestOptions());
      request.then(
        (response) => {
          var data = null;
          if (response && response.bodyText) {
            try {
              data = JSON.parse(response.bodyText);
              resolve(data);
              return;
            } catch (e) {
              //console.log("Could not parse the display");
            }
          }
          resolve(null);
        },
        (response) => {
          //console.error(response);
          reject(
            response.body.detail ||
            response.body.non_field_errors?.[0] ||
            response.statusText
          );
        }
      );
    });
  }

  async history(query, fitInTimeWindow) {
    let data_ids = query.data_ids.split(",");
    if (!fitInTimeWindow || data_ids.length == 1) {
      return this.fetchSamples(query, "between");
    }
    let promises = [
      this.fetchSamples(query, "before"),
      this.fetchSamples(query, "between"),
      this.fetchSamples(query, "after")
    ];
    return new Promise((resolve) => {
      Promise.all(promises).then((result) => {
        if ((result[1] || []).length) {
          let sample = null;
          let edges = {
            minDateTime: 0,
            maxDateTime: 0,
            left: {},
            right: {}
          };
          // promises:
          let before = result[0] || [];
          let between = result[1] || [];
          let after = result[2] || [];

          // begin test only
          // mess up with datetime in order to create gaps
          // between = between.map((item) => {
          //   item.date_time = new Date(
          //     new Date(item.date_time).getTime() +
          //       parseInt(Math.random(10) * 100000)
          //   ).toISOString();
          //   return item;
          // });
          // end test only

          // right edge - based on backend default sort order
          // desc to asc
          between.sort((a, b) => {
            if (a.date_time < b.date_time) return 1;
            if (a.date_time > b.date_time) return -1;
            return 0;
          });
          (data_ids || []).forEach((dataId) => {
            sample = between.find(({ data_id }) => dataId == data_id);
            edges.right[dataId] = sample || null;
            if (sample) {
              let dt = new Date(sample.date_time).getTime();
              if (!edges.maxDateTime || dt > edges.maxDateTime) {
                edges.maxDateTime = dt;
              }
            }
          });
          // desc to asc
          between.sort((a, b) => {
            if (a.date_time > b.date_time) return 1;
            if (a.date_time < b.date_time) return -1;
            return 0;
          });
          // left edge samples
          (data_ids || []).forEach((dataId) => {
            sample = between.find(({ data_id }) => dataId == data_id);
            edges.left[dataId] = sample || null;
            if (sample) {
              let dt = new Date(sample.date_time).getTime();
              if (!edges.minDateTime || dt < edges.minDateTime) {
                edges.minDateTime = dt;
              }
            }
          });

          // projection
          (data_ids || []).forEach((dataId) => {
            // left edge
            sample = edges.left[dataId];
            if (sample) {
              let lastSample = before.find(({ data_id }) => dataId == data_id);
              if (lastSample) {
                let lastSampleTime = new Date(lastSample.date_time).getTime();
                let prop =
                  (edges.minDateTime - lastSampleTime) /
                  (new Date(sample.date_time).getTime() - lastSampleTime);
                let value =
                  (parseFloat(sample.value) - parseFloat(lastSample.value)) *
                  prop +
                  parseFloat(lastSample.value);
                value = value > 0 && value < 1 ? Math.round(value) : value;
                between.push({
                  data_id: dataId,
                  date_time: new Date(edges.minDateTime).toISOString(),
                  value: value
                });
              }
            }
            // right edge
            sample = edges.right[dataId];
            if (sample) {
              let nextSample = after.find(({ data_id }) => dataId == data_id);
              if (nextSample) {
                let nextSampleTime = new Date(nextSample.date_time).getTime();
                let prop =
                  1 -
                  (nextSampleTime - edges.maxDateTime) /
                  (nextSampleTime - new Date(sample.date_time).getTime());
                let value =
                  (parseFloat(nextSample.value) - parseFloat(sample.value)) *
                  prop +
                  parseFloat(sample.value);
                value = value > 0 && value < 1 ? Math.round(value) : value;
                between.push({
                  data_id: dataId,
                  date_time: new Date(edges.maxDateTime).toISOString(),
                  value: value
                });
              }
            }
          });
          resolve(between);
        } else {
          resolve([]);
        }
      });
    });
  }

  async checkTask(id, query) {
    return new Promise((resolve) => {
      let url = `${baseurl}async_tasks_results/${id}/?format=json`;
      let auth = new Auth();
      if (query) {
        for (var prop in query) {
          let vlr = encodeURIComponent(query[prop]);
          url += `&${prop}=${vlr}`;
        }
      }
      let request = Vue.http.get(url, auth.requestOptions());
      request.then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let body = ("body" in error && error.body) || {};
          if (typeof body == "string") {
            resolve(body);
          } else {
            let msg = [];
            for (var i in body) {
              msg.push(i + ": " + body[i]);
            }
            if (msg.length > 8) {
              resolve(msg.splice(8).join("\n"));
            } else {
              resolve(msg.join("\n"));
            }
          }
        }
      );
    });
  }
}
