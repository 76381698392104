import Vue from "vue";
import VueResource from "vue-resource";
import Utils from "@/plugins/utils.js";
import ScreenService from "./screen.js";
import { result, isEqual } from "lodash";

import Panels from "@/assets/dashboard/panels.json";

Vue.use(VueResource);
Vue.use(Utils);

const production_static_app_dir_type = "hostname"; // database || hostname
const _vue = new Vue();

const getUnpublishedList = () => {
  let lst = [];
  for (var key in localStorage) {
    if (/^dashboard\(\-\d+\)$/gi.test(key)) {
      lst.push(key.match(/\d+/gi)[0]);
    }
  }
  return lst.sort();
};

const nextId = () => {
  let ids = getUnpublishedList();
  let max = ids.length ? Math.max.apply(Math, ids) : 0;
  return ++max * -1;
};

const IndexTableDrafts = () => {
  let lst = [];
  for (var key in localStorage) {
    if (/^dashboard\(-?\d+\)$/gi.test(key)) {
      lst.push({
        key: key,
        screenId: key.match(/\-?\d+/gi)[0],
        isNew: /\(-\d+\)$/gi.test(key)
      });
    }
  }
  return lst;
};

const removeFromLocalStorage = async (value) => {
  let lst = value instanceof Array ? value : [value];
  lst.forEach((key) => {
    localStorage.removeItem(key);
  });
};

const editorSettings = (payload) => {
  const key = "editorSettings";
  if (payload && typeof payload == "object") {
    localStorage.setItem(key, JSON.stringify(payload));
    return payload;
  }
  let item = localStorage.getItem(key);
  if (item || typeof item == "string") {
    return JSON.parse(item);
  }
  return {
    recent: [],
    togglePanelState: {}
  };
};

const _updateRecent = async (screenId) => {
  if (!screenId) return;
  const limit = 5;
  let entry = editorSettings();
  entry.recent = entry.recent.filter((i) => i.screenId != screenId);
  if (entry.recent.length >= limit) {
    entry.recent.splice(limit - 1);
  }
  entry.recent.unshift({
    screenId: screenId,
    updated_at: new Date().toISOString()
  });
  editorSettings(entry);
};

/*
Single localstorage crud for any dashboard related operation
  get    : _dashboard = (id)
  save   : _dashboard = (id, payload)
  remove : _dashboard = (id, null)
*/
const draftDB = (id, payload) => {
  if (id) {
    let key = `dashboard(${id})`;
    if (payload && typeof payload == "object") {
      localStorage.setItem(key, JSON.stringify(payload));
      window.dispatchEvent(new Event("storage"));
      _updateRecent(id);
      return payload;
    } else if (typeof payload == "undefined") {
      let item = localStorage.getItem(key);
      if (item || typeof item == "string") {
        return JSON.parse(item);
      }
      return null;
    } else if (payload === null) {
      localStorage.removeItem(key);
      window.dispatchEvent(new Event("storage"));
      return null;
    }
  }
};

const trackDataId = (dataList, connectorId, sourceId) => {
  let data = (dataList || []).find(({ id }) => id == sourceId) || null;
  // if instance has been changed since last time it opened this template
  // replace the previous dataTo by the one under the proper connector
  if (data && connectorId && data.clp_id != connectorId) {
    data =
      (dataList || []).find(
        ({ clp_id, reference_id }) =>
          reference_id == data.reference_id && clp_id == connectorId
      ) || null;
    if (data) {
      return data.id;
    }
  }
  return sourceId;
};

// It combines new dataId list with the previous one
// whether it is available, it tries to make use of suitable data information
const replaceDataList = (dataList, panel, params, attr) => {
  let connectorId = params.connectorId;
  let replaceByList = params[panel.name]?.dataList || [];
  let series = panel?.options[attr] || [];
  if (!(replaceByList || []).length || !(series || []).length) return;
  if ((series || []).length >= replaceByList.length) {
    let data = null;
    for (var i = 0; i < replaceByList.length; i++) {
      series[i].data_id = trackDataId(
        dataList,
        connectorId,
        replaceByList[i].data_id
      );
      // chart series only:
      if (data && series[i]?.chartOptions) {
        series[i].chartOptions.name = data.name;
        // serie color
        if (
          data?.portal_data?.color &&
          series[i].chartOptions?.itemStyle?.color
        ) {
          series[i].chartOptions.itemStyle.color = data.portal_data.color;
        }
        // wave form
        if (
          data?.portal_data?.wave_form &&
          series[i].chartOptions?.lineStyle?.waveForm
        ) {
          series[i].chartOptions.lineStyle.waveForm =
            data.portal_data.wave_form;
        }
      }
    }
    panel.options[attr] = series;
  }
};

const DataListBasedPanels = {
  SynopticPanel: {
    config: null,
    parser(panel, params, dataList) {
      let connectorId = params.connectorId;
      (panel?.options?.controls || [])
        .filter(
          (ctrl) =>
            ctrl.enabled &&
            ctrl.synopticComponent &&
            ctrl.name in (params || {})
        )
        .forEach((ctrl) => {
          var dataId = trackDataId(
            dataList,
            connectorId,
            params[ctrl.name]?.data_id || params[ctrl.name] || ""
          );
          if (dataId != ctrl.data_id) {
            ctrl.data_id = dataId;
          }
        });
    },
    hasDataListSupport(panel) {
      return false;
    }
  },
  EquipmentDataPanel: {
    config: {
      collapsed: true,
      dataSelectionOnly: true,
      showAddAllDataButton: false,
      showAddNewDataButton: true,
      multiConnector: true,
      selectable: false
    },
    parser(panel, params, dataList) {
      if (!(params || {})[panel.name]) return;
      replaceDataList(dataList, panel, params, "dataList");
    },
    hasDataListSupport(panel) {
      return true;
    }
  },
  EquipmentHistoryPanel: {
    config: {
      collapsed: true,
      dataSelectionOnly: false,
      showAddAllDataButton: false,
      showAddNewDataButton: true,
      multiConnector: true,
      selectable: true
    },
    parser(panel, params, dataList) {
      if (!(params || {})[panel.name]) return;
      replaceDataList(dataList, panel, params, "dataList");
    },
    filter(lst) {
      return (lst || []).filter(({ history_enabled }) => history_enabled);
    },
    hasDataListSupport(panel) {
      return true;
    }
  },
  DashboardTablePanel: {
    config: {
      collapsed: true,
      dataSelectionOnly: false,
      showAddAllDataButton: false,
      showAddNewDataButton: true,
      multiConnector: true,
      selectable: false
    },
    address(r, c) {
      let char = c <= 90 ? String.fromCharCode(c + 65) : "!"; // 90=Z and it is a too big table
      return `${char}${r + 1}`;
    },
    mapDataCells(panel) {
      let dataList = panel.options.dataSetConfig.dataList || [];
      // only map original datalist one
      if (!panel.options._dataCellRef) {
        let entry = null;
        for (var r = 0; r < panel?.options?.sheet?.length; r++) {
          for (var c = 0; c < panel?.options?.sheet[r]?.length; c++) {
            var dataId = panel.options.sheet[r][c]?.data_id || "";
            if (dataId) {
              var pos = dataList.findIndex((i) => i.data_id == dataId);
              entry = entry || {};
              entry[dataId] = entry[dataId] || [];
              entry[dataId].push({
                r: r,
                c: c,
                address: this.address(r, c),
                dataListPos: pos,
                dataId: dataId
              });
            }
          }
        }
        // only set if sheet makes reference to a dataId
        if (entry) {
          panel.options._dataCellRef = entry;
        }
      }
    },
    parser(panel, params, dataList) {
      if (!(params || {})[panel.name]) return;
      this.mapDataCells(panel);
      // if no data mapping is required, just return
      if (!panel.options._dataCellRef) return;
      //==================================
      // sheet cells out of history dataset
      let series = panel?.options?.dataSetConfig?.dataList || [];
      (params[panel.name]?.mappedData || []).forEach((item) => {
        if (!item?.from?.data_id || !item?.to?.data_id) return;
        let from = (dataList || []).find(({ id }) => id == item?.from?.data_id);
        var cells = panel.options._dataCellRef[item.from.data_id] || [];
        if (!cells.length && from?.reference_id) {
          cells = panel.options._dataCellRef[from?.reference_id] || [];
        }
        // it only considers the ones which were not present at the history dataset (datalistpos=-1)
        cells.forEach((cell) => {
          panel.options.sheet[cell.r][cell.c].data_id = item.to.data_id;
          if (cell.dataListPos >= 0 && cell.dataListPos < series.length) {
            series[cell.dataListPos].data_id = item.to.data_id;
          }
        });
      });
    },
    filter(lst) {
      // return (lst || []).filter(({ history_enabled }) => history_enabled);
      return lst;
    },
    hasDataListSupport(panel) {
      return panel?.options?.dataSetConfig?.address &&
        panel?.options?.dataSetConfig?.dataList?.length
        ? true
        : false;
    }
  },
  EquipmentHistoryChartPanel: {
    config: {
      collapsed: true,
      dataSelectionOnly: false,
      showAddAllDataButton: false,
      showAddNewDataButton: true,
      multiConnector: true,
      selectable: false
    },
    parser(panel, params, dataList) {
      if (!(params || {})[panel.name]) return;
      replaceDataList(dataList, panel, params, "data");
    },
    hasDataListSupport(panel) {
      return true;
    }
  },
  EquipmentAlarmPanel: {
    config: {
      collapsed: true,
      dataSelectionOnly: false,
      showAddAllDataButton: false,
      showAddNewDataButton: true,
      multiConnector: true,
      selectable: false
    },
    parser(panel, params, dataList) {
      if (!(params || {})[panel.name]) return;
      replaceDataList(dataList, panel, params, "dataList");
    },
    hasDataListSupport(panel) {
      return true;
    }
  }
};

const scriptDefaults = () => ({
  newContent:
    '{\
    $value: "()=>{return data?.current_value?.value||null;}",\
    $connectorName: "()=>{return data?.device?.connector?.name||\'\';}",\
    $deviceName: "()=>{return data?.device?.name||\'\';}",\
    $fahrenheit: "()=>{return data?.current_value?.value*1.8+32}", // for reference only\
    $fn:\
      "/*\n remark\n*/\n(p1,p2) => {\n\treturn data.current_value.value * p1 + p2;\n}"\
    }',
  newFunction:
    '/*\n    @name: $function_name\n   @brief: \n  @author: $first_name $last_name ($email)\n @created: $created\n*/\n\n()=>{\n\treturn ""\n}',
  newScreen: {
    name: "main.js",
    description: "Javascript",
    revision_code: "",
    revision_comment: "",
    public: false,
    portal_data: {
      type: "script",
      tags: []
    },
    process_area: null
  }
});
const panelPosition = (template, panelName) => {
  for (var r in template.layout) {
    for (var c in template.layout[r]) {
      for (var p in template.layout[r][c]?.panels || []) {
        if (template.layout[r][c].panels[p] == panelName) {
          // console.log(`${panelName} ${r} ${c}`);
          return { row: parseInt(r), col: parseInt(c) };
        }
      }
    }
  }
  return null;
};

export {
  getUnpublishedList,
  nextId,
  IndexTableDrafts,
  removeFromLocalStorage,
  editorSettings,
  draftDB,
  DataListBasedPanels,
  panelPosition,
  scriptDefaults
};

export default class DashboardService {
  // returns true if it is connected to the local webserver

  isLocalhost() {
    return (
      document.location.hostname == "localhost" ||
      document.location.hostname == "bs-local.com" ||
      document.location.hostname == "127.0.0.1" ||
      document.location.hostname == "0.0.0.0"
    );
  }

  // Initial website setup
  async setup() {
    let self = this;
    return new Promise((resolve) => {
      Vue.http.options.dashboard = "";
      // Try to get configuration from local through index.json
      if (this.isLocalhost()) {
        Vue.http.get("/index.json").then(
          (response) => {
            if (response && response.bodyText) {
              try {
                let data = JSON.parse(response.bodyText);
                if ("app_static_dir" in data && data.app_static_dir) {
                  Vue.http.options.dashboard =
                    "/static/" + data.app_static_dir || "/static/hitecnologia";
                  let config_url = Vue.http.options.dashboard + "/config.json";
                  resolve(self.fetchConfiguration(config_url));
                } else {
                  // since static dir was not defined - try again to get configuration from the api
                  self.setRemoteUp(resolve);
                }
              } catch (e) {
                //console.log("Could not read index.json");
              }
            }
          },
          (error) => {
            // local configuration file not found - try get configuration from api
            console.log(error);
            self.setRemoteUp(resolve);
          }
        );
      } else {
        self.setRemoteUp(resolve);
      }
    });
  }

  // Setup for running it remotely
  setRemoteUp(resolve) {
    let self = this;
    let config_url = "";
    if (production_static_app_dir_type == "database") {
      let root_app = document.location.origin; // replaces api by the api pattern
      let root_api = root_app.replace(/app\./, "api.");
      config_url = root_api + "/rest/v1/app_configurations/?format=json";
    } else if (production_static_app_dir_type == "hostname") {
      let tmpVue = new Vue();
      let app_static_dir =
        "/static/" +
        (tmpVue.$utils.gup("app_static_dir") || document.location.hostname);
      Vue.http.options.dashboard = app_static_dir;
      config_url = app_static_dir + "/config.json";
    }
    if (config_url) {
      resolve(self.fetchConfiguration(config_url));
    } else {
      resolve(null);
    }
  }

  async fetchConfiguration(url) {
    return new Promise((resolve) => {
      Vue.http.get(url).then(
        (response) => {
          try {
            if (response && response.bodyText) {
              let data = JSON.parse(response.bodyText);
              // static dir if not set yet
              if ("app_static_dir" in data) {
                Vue.http.options.dashboard = "/static/" + data.app_static_dir;
              }
              // configuration
              let config = null;
              if ("app_configuration" in data) {
                config = data.app_configuration;
              } else {
                config = data;
              }
              Vue.http.options.root = config.api_url || "";
              if (!Vue.http.options.root) {
                var env =
                  (config &&
                    config.env &&
                    (config.dev ? config.env.dev : config.env.prod)) ||
                  {};
                if ("api_url" in env) {
                  Vue.http.options.root = env.api_url || "";
                }
              }

              if (config.api_key) {
                Vue.http.options.apiKey = config.api_key;
              }
              ///////////////////////////////////////////////////
              /* replace config variables */
              var tmpVue = new Vue();
              var variables = {
                app_static_dir: Vue.http.options.dashboard.replace(
                  /\/static\//,
                  ""
                ),
                base_url: document.location.origin,
                hostname: document.location.hostname
              };
              config = tmpVue.$utils.replaceAll(config, variables);
              ///////////////////////////////////////////////////
              Vue.http.options.config = config;
              resolve(config);
              return;
            }
          } catch (e) {
            //console.log(`Could not parse the ${Vue.http.options.dashboard}/config`);
          }
          resolve(null);
        },
        () => {
          resolve(null);
        }
      );
    });
  }

  // it avoids more than one "." on data_id attribute
  removeDataIdExtraDots(json) {
    for (var k in json) {
      if (typeof json[k] == "object") {
        this.removeDataIdExtraDots(json[k]);
      } else {
        if (k == "data_id") {
          // check if it is converting back (to number id)
          if (isNaN(parseInt(json[k]))) {
            let v = json[k].split(".");
            if (v.length > 2) {
              let device_name = v[0];
              let data_name = v.splice(1).join("");
              json[k] = `${device_name}.${data_name}`;
            }
          }
        }
      }
    }
    return json;
  }

  parseTemplate(body) {
    let result = null;
    try {
      if (body) {
        // it does not convert font-size on editor or desktop (yet)
        if (
          !document.location.pathname.startsWith("/dashboard/screen") &&
          Utils.iOS()
        ) {
          let basePX = parseInt(
            window
              .getComputedStyle(document.body)
              .getPropertyValue("font-size")
              .replace(/px/)
          );
          body = body.replace(/font-size":\s"\d+pt"/gi, function(matched) {
            return matched.replace(/\d+pt/gi, function(value) {
              let s = (parseInt(value.match(/\d+/gi)[0]) / basePX) * 1.3333;
              return `${s}em`;
            });
          });
        }
        let template = JSON.parse(body);
        let tmpVue = new Vue();
        let variables = {
          app_static_dir: Vue.http.options.dashboard.replace(/\/static\//, ""),
          base_url: document.location.origin,
          hostname: document.location.hostname
        };
        template = this.removeDataIdExtraDots(template);
        template = tmpVue.$utils.replaceAll(template, variables);
        const cur_webapp_version = Vue.config.packageVersion || "";
        const tpl_webapp_version = template?.webapp_version || "";
        // Backward compatibility - inject new panel properties and update rules
        (template?.panels || []).forEach((panel) => {
          let tplPanel = (
            Panels.find((item) => item.template.template == panel.template) || {
              template: null
            }
          ).template;
          if (tplPanel) {
            if (tplPanel?.rule) {
              panel.rule = tplPanel.rule;
            }
            panel.options = { ...(tplPanel?.options || {}), ...panel.options };
            if (panel.template == "SynopticPanel") {
              (panel?.options?.controls || []).forEach((control) => {
                if (control?.synopticComponent?.on) {
                  for (var eventName in control?.synopticComponent?.on) {
                    let evt = control?.synopticComponent?.on[eventName];
                    if (evt.length) {
                      control.synopticComponent.on[eventName] = {
                        actions: JSON.parse(JSON.stringify(evt))
                      };
                    }
                  }
                }
              });
            } else {
              // apply new missing styles
              if (panel.template == "EquipmentHistoryPanel" && !panel.style) {
                panel.style = { ...tplPanel.style, ...panel.style };
                panel.style["min-height"] = "452px";
              } else {
                panel.style = { ...tplPanel.style, ...panel.style };
              }
              // update overflow if old version
              if (
                cur_webapp_version != tpl_webapp_version &&
                template.render_version == 1 &&
                (!tpl_webapp_version || tpl_webapp_version < "1.2.02")
              ) {
                if ("overflow-y" in tplPanel.style) {
                  panel.style["overflow-y"] = tplPanel.style["overflow-y"];
                }
              }
            }
            // data_id is now only valid within the panel scope
            // if defined, move "alarm_counter" from dashboard to panel scope
            if (template?.alarm_counter?.data_id) {
              panel.options = panel.options || {};
              panel.options.alarm_counter = {
                data_id: template?.alarm_counter?.data_id
              };
            }

            if (panel?.toolbar?.length) {
              (panel.toolbar || []).forEach((control) => {
                if (control?.on) {
                  for (var eventName in control?.on) {
                    let evt = control?.on[eventName];
                    if (evt.length) {
                      control.on[eventName] = {
                        actions: JSON.parse(JSON.stringify(evt))
                      };
                    }
                  }
                }
              });
            }
          }
        });
        delete template.alarm_counter;
        result = template;
      }
    } catch (e) {
      // do nothing
    }
    return result;
  }

  /*
  todo: this method must be deleted - you should get template from modules
  */
  getLocal(contract, query, resolve) {
    let self = this;
    let url = Vue.http.options.dashboard + "/screens/" + query.id + ".json";
    Vue.http.get(url).then(
      (response) => {
        let template = self.parseTemplate(response.bodyText);
        // compatible it with rest
        resolve({
          id: query.id,
          template: template,
          public: true,
          contract_id: contract?.id || 0,
          description: "",
          revision_code: "",
          revision_comment: ""
        });
      },
      (error) => {
        console.log(error);
        resolve(null);
      }
    );
  }

  /*
  todo: this method must be deleted - you should get template from modules
  */
  async fetchDashboardTemplate(contract, query) {
    let self = this;
    return new Promise((resolve) => {
      // get public screen (if applicable)
      if (contract && contract.allowed_custom_screens) {
        let srv = new ScreenService();
        srv.get(query.id).then((response) => {
          if (response && response.content) {
            response.template = self.parseTemplate(response.content);
            delete response.content;
            result(response);
          } else {
            self.getLocal(contract, query, resolve);
          }
        });
      } else {
        // get local screen
        self.getLocal(contract, query, resolve);
      }
    });
  }

  async getTemplate(url) {
    return new Promise((resolve) => {
      this.getFileContent(url).then((response) => {
        if (response) {
          resolve(this.parseTemplate(response));
        } else {
          resolve(null);
        }
      });
    });
  }

  async getFileContent(url) {
    return new Promise((resolve) => {
      url += "?_=" + new Date().getTime();
      Vue.http.get(url).then(
        (response) => {
          resolve(response.bodyText);
        },
        (e) => {
          console.log(e);
          resolve(null);
        }
      );
    });
  }

  replaceJsonValue(json, field, oldvalue, newvalue) {
    // begin test only
    // let searchTest="dispositivo_1.Status de operação da bomba 1";
    // if (oldvalue==searchTest && (json?.data_id||"")==searchTest){
    //   console.log("control found");
    // }
    // end test only
    for (var k in json) {
      if (k == "testDataValue" || k == "tmp") {
        delete json[k];
        this.replaceJsonValue(json, field, oldvalue, newvalue);
      } else {
        if (typeof json[k] == "object") {
          this.replaceJsonValue(json[k], field, oldvalue, newvalue);
        } else {
          //if (k == field && json[k] == oldvalue) {
          if (k == field && json[k]) {
            if (isNaN(json[k])) {
              // let a=(_vue.$utils.asKey(_vue.$utils.removeDiacritics(json[k]))+"").toLowerCase();
              // let b=(_vue.$utils.asKey(_vue.$utils.removeDiacritics(oldvalue))+"").toLowerCase();
              let a = (json[k] + "").toLowerCase();
              let b = (oldvalue + "").toLowerCase();
              if (a == b) {
                json[k] = newvalue;
              }
            } else if (json[k] == oldvalue) {
              // converting back (number to string now)
              json[k] = newvalue;
            }
          }
        }
      }
    }
  }

  renderDashboardConfiguration(tpl, tplData) {
    var self = this;
    var json = JSON.parse(JSON.stringify(tpl));
    if (json && tplData) {
      json = this.removeDataIdExtraDots(json);
      var _parse = function(field, obj) {
        for (var k in obj) {
          if (typeof obj[k] == "object") {
            _parse(k, obj[k]);
          } else {
            self.replaceJsonValue(json, field, k, obj[k]);
          }
        }
      };
      _parse("root", tplData);
    }
    return json;
  }

  comparePanels(aTpl, bTpl) {
    const _flatPanel = (panel) => {
      if (panel) {
        let n = JSON.parse(JSON.stringify(panel));
        delete n.id;
        for (let i in n?.options?.controls || []) {
          delete n?.options?.controls[i].id;
        }
        return n;
      }
      return null;
    };
    let diff = [];
    let aPanels = aTpl?.panels || [];
    let bPanels = bTpl?.panels || [];
    if (!aPanels.length != bPanels.length) {
      var aPanel = null;
      var bPanel = null;
      var checked = {};
      for (var aX in aTpl?.panels || []) {
        aPanel = aTpl?.panels[aX];
        checked[aPanel.name] = true;
        bPanel =
          (bTpl?.panels || []).find(({ name }) => name == aPanel.name) || null;
        if (!bPanel || !isEqual(_flatPanel(aPanel), _flatPanel(bPanel))) {
          diff.push(aPanel.name);
        }
      }
      for (var bX in bTpl?.panels || []) {
        bPanel = bTpl?.panels[bX];
        if (!(bPanel.name in checked)) {
          aPanel =
            (aTpl?.panels || []).find(({ name }) => name == bPanel.name) ||
            null;
          if (!aPanel || !isEqual(_flatPanel(aPanel), _flatPanel(bPanel))) {
            diff.push(bPanel.name);
          }
        }
      }
    }
    return diff;
  }
}
